@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Nunito", sans-serif;
}

.form-group {
    padding: 10px;
    /* padding-right: 10px; */
}

.btn1 {
    padding: 8px;
    margin-left: 10px;

}

.card-box {
    padding: 30px;
    margin-top: 25px;
    margin-bottom: 30px;
    border: 0px;
    border-radius: 8px;
    position: relative;
    flex-direction: column;
    display: flex;
    background-color: #fff;
    /* box-shadow: 1px 2px 40px 0 rgba(57, 102, 242, 0.05); */
}

.card-header {
    background-color: #fff;
    border-bottom: none;
    padding: 10px;
    border-bottom: 1px solid #f8f8f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-body {
    height: auto;
    width: 100%;
    position: relative;
}

/* .table-header{
    border: 1px solid #e8efff;
    background: #f9f9f9;
    cursor: pointer;
} */

.table-header table {
    margin: 0px;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
}

.table thead th {
    color: #fff;
    padding: 20px;
    height: 60px;
    border-style: solid;
    border-width: 0;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;
}

.contest .table thead th {
    color: #fff;
    padding: 14px;
    height: 31px;
    border-style: solid;
    border-width: 0;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;
    background: black;

}

.contest .table thead th:hover {
    text-decoration: none;
}

.table thead th:hover {
    text-decoration: underline #ed3237 3px;
}

.table-header tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    /* background-color: #f1f4fb; */
    text-align: center;
    padding: 14px;
    height: 31px;
    border: 1px solid #e8efff;
    background: #f9f9f9;
    cursor: pointer;
    font-size: 14px;
}

.table-header tr:hover {
    background-color: rgb(214, 209, 209);
}

.circle {
    color: #11d89c !important;
    font-size: 12px;
}

.delete {
    color: rgb(67, 67, 209);
}


.plus {
    color: #11d89c !important;
    font-size: 25px;
    font-weight: 900;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111009;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999999;
}