* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}




/* .container{
  display: flex;
} */

main {
  width: 100%;
}

.textblue {
  color: #ed3237 !important;
}





.bgblue {
  background-color: #ed3237 !important;

}

.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  /* ensure it's above other elements */
  background-color: rgb(178 173 172 / 63%);
  /* background-color: #eeeeee; */
}

.sidebar {
  background: #000;
  color: #fff;
  min-height: 100vh;
  height: 100%;
  width: 400px;
  transition: all 0.5s;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}


.top_section img {
  height: 70px;
  cursor: pointer;
  padding: 5px 20px;
}

.top_section .icon {
  height: 40px;
  align-items: center !important;
  padding: 8px 0px;
}

.bars {
  display: flex;
  font-size: 20px;
  padding: 24px 10px;
}

.link {
  display: flex;
  color: #fff;
  border-radius: 10px;
  /* padding: 12px 18px; */
  margin: 12px;
  gap: 14px;
  transition: all 0.5s;
  text-decoration: none;
  align-items: center !important;
  padding: 10px 12px;
}

.link:hover {
  background: #ed3237;
  color: #fff;
  transition: all 0.5s;
}

.bg-yellow {
  background-color: #f89e32 !important;
  border: 0 !important;
}

.active {
  background: #ed3237;
  color: #fff;
  /* padding: 10px 5px; */
}

.icon,
.link_text {
  font-size: 14px;
}

.MuiDataGrid-topContainer.MuiDataGrid-container--top.css-1oudwrl,
.MuiDataGrid-columnHeaders.css-1pmk00y-MuiDataGrid-columnHeaders,
.css-yrdy0g-MuiDataGrid-columnHeaderRow,
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor {
  background-color: #000 !important;
  color: white;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
  fill: white;
}


.formbox .input-group .form-control {
  border: 0;

}

.formbox .input-group {
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.formbox .input-group span {
  padding: 6px;
}

.logoutbox {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #f26822;
}

main {
  overflow: hidden;
}

.custombtn {
  background-color: #ed3237 !important;
  font-size: 14px;
  color: white !important;
}

.submitbtn {
  background-color: #f89e32 !important;
  color: white !important;
}

.sub-menu {
  padding: 0px 24px;
}

.stdropdown-input.stsearch-box input {
  border: 0;

}

.stdropdown-input.stsearch-box input:focus-visible {
  border: 0;
  outline: none;
}

.stsearch-box {
  padding: 0 !important;
  background-color: white !important;
  border-radius: 4px;
  box-shadow: 0 0 4px white;
}

.stdropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
  font-family: "Twemoji Mozilla" !important;
}

.stdropdown-input {
  padding: .375rem .75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
}

/*userdetail*/
.userdetails .detailsrow {
  padding-left: 100px;
  padding-right: 100px;
}

.userdetails .detailsrow .userfacebox {
  position: relative;
  bottom: 34px;

}

.userdetails .detailsrow .userfaceboxinner {
  background-color: #cbd5e1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.userdetails .detailsrow .resetbtn {
  background-color: #3b82f6;
  border: none;
  margin-right: 21px;
}

.userdetails .detailsrow .rejectbtn {
  background-color: #ef4444;
  border: none;


}

.userdetails .detailsrow .approvebtn {
  background-color: #22c55e;
  border: none;
  margin-top: 11px;
}

.text-blue {
  color: #0367be;
}

.offerdetails .accordion-button:not(.collapsed) {
  background-color: white !important;
}

.offerdetails .accordion-button:focus {
  border-color: none !important;
}

.offerdetails .accordion {
  box-shadow: none !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}

.table-responsive>.table>thead>tr>th,
.table-responsive>.table>tbody>tr>th,
.table-responsive>.table>tfoot>tr>th,
.table-responsive>.table>thead>tr>td,
.table-responsive>.table>tbody>tr>td,
.table-responsive>.table>tfoot>tr>td {
  white-space: nowrap;
}



.checkbox-con {
  margin: 10px;
  display: flex;
  align-items: center;
  color: white;
}

.checkbox-con input[type="checkbox"] {
  appearance: none;
  width: 50px;
  height: 22px;
  border: 2px solid #ff0000;
  border-radius: 20px;
  background: #f1e1e1;
  position: relative;
  box-sizing: border-box;
}

.checkbox-con input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  background: rgba(234, 7, 7, 0.5);
  border: 2px solid #ea0707;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 10px;
  transform: translate(13%, 15%);
  transition: all 0.3s ease-in-out;
}

.checkbox-con input[type="checkbox"]::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55021 5.84315L17.1568 16.4498L16.4497 17.1569L5.84311 6.55026L6.55021 5.84315Z' fill='%23EA0707' fill-opacity='0.89'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.1567 6.55021L6.55012 17.1568L5.84302 16.4497L16.4496 5.84311L17.1567 6.55021Z' fill='%23EA0707' fill-opacity='0.89'/%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 20px;
}

.checkbox-con input[type="checkbox"]:checked {
  border: 2px solid #02c202;
  background: #e2f1e1;
}

.checkbox-con input[type="checkbox"]:checked::before {
  background: rgba(2, 194, 2, 0.5);
  border: 2px solid #02c202;
  transform: translate(133%, 13%);
  transition: all 0.3s ease-in-out;
}

.checkbox-con input[type="checkbox"]:checked::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8185 0.114533C15.0314 0.290403 15.0614 0.605559 14.8855 0.818454L5.00187 12.5L0.113036 6.81663C-0.0618274 6.60291 -0.0303263 6.2879 0.183396 6.11304C0.397119 5.93817 0.71213 5.96967 0.886994 6.18339L5.00187 11L14.1145 0.181573C14.2904 -0.0313222 14.6056 -0.0613371 14.8185 0.114533Z' fill='%2302C202' fill-opacity='0.9'/%3E%3C/svg%3E");
  position: absolute;
  top: -14px;
  left: 5px;

}

.checkbox-con label {
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}