.card-body {
    height: 120px;
    width: 90%;
    border: 1px;
    padding-top: 10px;
}

.heading {
    padding: 30px 10px;
    line-height: 10px;
    font-weight: 600;
}

.card {
    border-radius: 20px;
}

.card-body span {
    font-weight: 600;
    color: #fff;
}

.card-body h3 {
    color: #fff;
    padding: 5px;
    position: relative;
    padding: 5px;
}

.card-body small {
    font-size: 15px;
    padding: 5px;
}

.icon-box i {
    color: #fff;
    position: absolute;
    right: 8%;
    font-size: 25px;
    top: 25%;
}